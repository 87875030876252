import { gql } from '@apollo/client';

export const PAGE_BY_HANDLE_FRAGMENT = gql`
  fragment PageFields on Page {
    id
    body
    updatedAt
    title
  }
`;
