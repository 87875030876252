export enum ContentType {
  RESOURCES = 'resource',
  PRODUCTS = 'product',
  AFFILIATE_PRODUCT = 'affiliateProduct',
  DISCUSSIONS = 'discussion',
  VILLAGE_FEED = 'villageFeedPost',
  VIDEO = 'video',
  RLS = 'rls',
  ARTICLE = 'article',
}
