import { gql } from '@apollo/client';

export const BLOG_FRAGMENT = gql`
  fragment BlogFields on Blog {
    id
    handle
    title
    seo {
      description
      title
    }
  }
`;

export const ARTICLE_FRAGMENT = gql`
  fragment ArticleFields on Article {
    id
    handle
    title
    contentHtml
    excerpt
    publishedAt
    tags
    blog {
      id
      handle
    }
    image {
      originalSrc
      width
      height
      altText
    }
    authorV2 {
      name
      bio
    }
    seo {
      description
      title
    }
  }
`;
