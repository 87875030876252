import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useMemo } from 'react';
import { initializeApollo } from '../graphql/client';

function useShopifyApolloClient(
  initialState = null,
): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}

export default useShopifyApolloClient;
