import debounce from 'lodash.debounce';

function asyncDebounce(func, wait): (...args: any[]) => Promise<unknown> {
  const debounced = debounce((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);
  return (...args) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    });
}

export default asyncDebounce;
