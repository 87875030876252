import {
  Attribute,
  AttributeInput,
  Checkout,
  CheckoutLineItemEdge,
} from '../../graphql';
import { Cart, LineItem } from '../../types';

function normalizeCustomAttributes(
  customAttributes: Attribute[],
): AttributeInput[] {
  if (!customAttributes) return null;
  return customAttributes?.map(({ key, value }) => ({
    key,
    value,
  }));
}

function normalizeLineItem({
  node: { id, customAttributes, title, variant, quantity },
}: CheckoutLineItemEdge): LineItem {
  return {
    id,
    variantId: String(variant?.id),
    productId: String(variant?.product?.id),
    title: `${title}`,
    quantity,
    customAttributes: normalizeCustomAttributes(customAttributes),
    variant: {
      id: String(variant?.id),
      sku: variant?.sku ?? '',
      name: variant?.title,
      vendor: variant?.product?.vendor,
      selectedOptions: variant?.selectedOptions,
      image: {
        url: variant?.image?.originalSrc,
      },
      requiresShipping: variant?.requiresShipping ?? false,
      price: variant?.priceV2?.amount,
      listPrice: variant?.compareAtPriceV2?.amount,
    },
    path: '',
    discounts: [],
    options: [
      {
        value: variant?.title,
      },
    ],
  };
}

export function normalizeCart(checkout?: Checkout): Cart {
  if (!checkout) return null;

  return {
    id: checkout.id,
    customerId: '',
    email: '',
    createdAt: checkout.createdAt,
    currency: {
      code: checkout.totalPriceV2?.currencyCode,
    },
    taxesIncluded: checkout.taxesIncluded,
    lineItems: checkout.lineItems?.edges.map(normalizeLineItem),
    lineItemsSubtotalPrice: +checkout.subtotalPriceV2?.amount,
    subtotalPrice: +checkout.subtotalPriceV2?.amount,
    totalPrice: checkout.totalPriceV2?.amount,
    discounts: [],
    shippingAddress: checkout.shippingAddress,
  };
}
