import { gql } from '@apollo/client';
import { PRODUCT_CONNECTION } from '../fragments';

export const GET_ALL_PRODUCTS_QUERY = gql`
 query products ($query: String!) {
    products(first: 100, query: $query) {
      edges {
        node {
          ${PRODUCT_CONNECTION}
        }
      }
    }
  }
`;

export const GET_ALL_GIFT_PRODUCTS_QUERY = `
  query GetAllGiftProducts($first: Int = 100) {
    products(
      first: $first
      query: "tag:gift"
      sortKey: CREATED_AT
      reverse: true
    ) {
      edges {
        node {
          ${PRODUCT_CONNECTION}
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
