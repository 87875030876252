import Cookies from 'js-cookie';

import {
  SHOPIFY_CHECKOUT_ID_COOKIE,
  SHOPIFY_CHECKOUT_URL_COOKIE,
  SHOPIFY_COOKIE_EXPIRE,
} from '../constants';
import { Checkout, useCheckoutCreateMutation } from '../graphql';

function useCheckoutCreate(): () => Promise<Checkout> {
  const [checkoutCreateMutation] = useCheckoutCreateMutation();

  async function checkoutCreate() {
    const { data } = await checkoutCreateMutation();
    const checkout = data?.checkoutCreate?.checkout;
    const checkoutId = checkout?.id;

    if (checkoutId) {
      const options = {
        expires: SHOPIFY_COOKIE_EXPIRE,
      };
      Cookies.set(SHOPIFY_CHECKOUT_ID_COOKIE, checkoutId, options);
      Cookies.set(SHOPIFY_CHECKOUT_URL_COOKIE, checkout?.webUrl, options);
    }

    return checkout as Checkout;
  }

  return checkoutCreate;
}

export default useCheckoutCreate;
