import React, { useState, useEffect, useMemo, useCallback } from 'react';

import Cookies from 'js-cookie';
import {
  SHOPIFY_CHECKOUT_ID_COOKIE,
  SHOPIFY_CHECKOUT_URL_COOKIE,
} from '@/services/shopify';
import { getCheckoutId, useCheckoutCreate } from '../../checkout';
import {
  Checkout,
  useGetCheckoutLazyQuery,
  useCheckoutLineItemsReplaceMutation,
  useCheckoutShippingAddressUpdateMutation,
  MailingAddressInput,
} from '../../graphql';
import { normalizeCart, asyncDebounce } from '../../utils';
import { Cart, LineItem } from '../../types';

import { CartContext } from './CartContext';
import decodeStorefrontNodeId from '@/utils/decodeStorefrontNodeId';

function isValidVariantId(variantId: string) {
  return !(!variantId || variantId === 'undefined');
}

function removeLineItem(
  lineItems: LineItem[],
  currentVariantId: string,
): LineItem[] {
  return lineItems?.reduce((items, lineItem) => {
    if (
      isValidVariantId(lineItem.variantId) &&
      lineItem.variantId !== currentVariantId
    ) {
      const { variantId, quantity, customAttributes } = lineItem;
      items.push({
        variantId,
        quantity,
        ...(customAttributes && { customAttributes }),
      });
    }
    return items;
  }, []);
}

const CartProvider: React.FC = ({ children }) => {
  const [
    getCheckout,
    { data, loading, called, error: checkoutError },
  ] = useGetCheckoutLazyQuery();

  const checkoutCreate = useCheckoutCreate();
  const [replaceCheckoutLineItems] = useCheckoutLineItemsReplaceMutation();
  const [
    updateShippingAddressMutaiton,
  ] = useCheckoutShippingAddressUpdateMutation();

  const [checkoutId, setCheckoutId] = useState(() => getCheckoutId());
  const [isCartLoading, setIsCartLoading] = useState<boolean>(true);
  const [cart, setCart] = useState<Cart>(null);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const performCreateCheckout = useCallback(async () => {
    try {
      const { id } = (await checkoutCreate()) ?? {};
      if (id) {
        setCheckoutId(id);
        getCheckout({
          variables: {
            checkoutId: id,
          },
        });
      }
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    if (called && !loading) {
      setIsCartLoading(false);
    }
  }, [loading, called]);

  useEffect(() => {
    if (checkoutId) {
      getCheckout({
        variables: {
          checkoutId,
        },
      });

      return;
    }

    performCreateCheckout();
  }, []);

  useEffect(() => {
    if (checkoutError) {
      performCreateCheckout();
    }
  }, [checkoutError]);

  useEffect(() => {
    if (!data) return;
    const { node: checkoutNode } = data;

    if ((checkoutNode as Checkout)?.completedAt) {
      performCreateCheckout();
      return;
    }

    const normalizedCard = normalizeCart(checkoutNode as Checkout);
    const calculatedTotalQuantity = normalizedCard?.lineItems?.reduce(
      (total, lineItem) => {
        return total + lineItem.quantity;
      },
      0,
    );

    setCart(normalizedCard);
    setTotalQuantity(calculatedTotalQuantity ?? 0);
  }, [data]);

  const updateShippingAddress = useCallback(
    (shippingAddress: MailingAddressInput) => {
      if (cart?.shippingAddress) return null;

      return updateShippingAddressMutaiton({
        variables: {
          checkoutId,
          shippingAddress,
        },
      });
    },
    [cart, checkoutId, updateShippingAddressMutaiton],
  );

  const addToCart = useCallback(
    ({ variantId, quantity = 1, customAttributes = null }) => {
      const lineItems = cart?.lineItems ?? [];
      if (!variantId) return null;
      let itemAdded = false;

      const newLineItems = [];
      lineItems?.forEach((item) => {
        if (!isValidVariantId(item.variantId)) return;
        if (item.variantId === atob(variantId) || item.variantId === variantId) {
          item.quantity += quantity;
          itemAdded = true;
        }

        newLineItems.push({
          variantId: item.variantId,
          quantity: item.quantity,
          ...(item.customAttributes?.length > 0 && {
            customAttributes: item.customAttributes,
          }),
        });
      });

      if (!itemAdded) {
        newLineItems?.push({
          variantId,
          quantity,
          ...(customAttributes && { customAttributes }),
        });
      }

      return replaceCheckoutLineItems({
        variables: {
          checkoutId,
          lineItems: newLineItems,
        },
      });
    },
    [cart?.lineItems, checkoutId, replaceCheckoutLineItems],
  );

  const removeFromCart = useCallback(
    (variantId: string) => {
      const lineItems = cart?.lineItems;
      if (!variantId || lineItems?.length < 1) return null;

      const updatedLineItems = removeLineItem(lineItems, variantId);

      return replaceCheckoutLineItems({
        variables: {
          checkoutId,
          lineItems: updatedLineItems,
        },
      });
    },
    [cart?.lineItems, checkoutId, replaceCheckoutLineItems],
  );

  const updateQuantity = useMemo(
    () =>
      asyncDebounce(async ({ variantId, quantity }) => {
        const lineItems = cart?.lineItems;
        if (!variantId || typeof quantity !== 'number' || lineItems?.length < 1)
          return null;

        let updatedLineItems = [];

        if (quantity === 0) {
          updatedLineItems = removeLineItem(lineItems, variantId);
        } else {
          let itemUpdated = false;
          lineItems?.forEach((item) => {
            if (!isValidVariantId(item.variantId)) return;

            let newQuantity = item.quantity;
            if (item.variantId === variantId) {
              newQuantity = quantity;
              itemUpdated = true;
            }

            updatedLineItems.push({
              variantId: item.variantId,
              quantity: newQuantity,
              ...(item.customAttributes && {
                customAttributes: item.customAttributes,
              }),
            });
          });

          if (!itemUpdated) return null;
        }

        return replaceCheckoutLineItems({
          variables: {
            checkoutId,
            lineItems: updatedLineItems,
          },
        });
      }, 500),
    [cart?.lineItems, checkoutId, replaceCheckoutLineItems],
  );

  const reset = useCallback(() => {
    setCart(null);
    setIsCartLoading(false);
    setTotalQuantity(0);
    Cookies.remove(SHOPIFY_CHECKOUT_ID_COOKIE);
    Cookies.remove(SHOPIFY_CHECKOUT_URL_COOKIE);
  }, []);

  const value = useMemo(() => {
    return {
      isCartLoading,
      cart,
      totalQuantity,
      updateShippingAddress,
      addToCart,
      removeFromCart,
      updateQuantity,
      reset,
    };
  }, [
    isCartLoading,
    cart,
    totalQuantity,
    updateShippingAddress,
    addToCart,
    removeFromCart,
    updateQuantity,
    reset,
  ]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default CartProvider;
