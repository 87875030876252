import { createContext, useContext } from 'react';
import { FetchResult } from '@apollo/client';

import {
  CheckoutLineItemsReplaceMutation,
  CheckoutShippingAddressUpdateMutation,
  MailingAddressInput,
} from '../../graphql';
import { Cart } from '../../types';

export type CartContextValueType = {
  isCartLoading: boolean;
  cart: Cart;
  totalQuantity: number;
  updateShippingAddress: (
    shippingAddress: MailingAddressInput,
  ) => Promise<
    FetchResult<
      CheckoutShippingAddressUpdateMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  addToCart: (params: {
    variantId: string;
    quantity?: number;
    customAttributes?: { key: string; value: string };
  }) => Promise<
    FetchResult<
      CheckoutLineItemsReplaceMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  removeFromCart: (
    variantId: string,
  ) => Promise<
    FetchResult<
      CheckoutLineItemsReplaceMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  updateQuantity: ({
    variantId,
    quantity,
  }: any) => Promise<
    FetchResult<
      CheckoutLineItemsReplaceMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  reset: () => void;
};

export const CartContext = createContext({} as CartContextValueType);

export function useCartContext(): CartContextValueType {
  return useContext(CartContext);
}
