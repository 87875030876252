export const SHOPIFY_CHECKOUT_ID_COOKIE = 'shopify_checkoutId';

export const SHOPIFY_CHECKOUT_URL_COOKIE = 'shopify_checkoutUrl';

export const SHOPIFY_COOKIE_EXPIRE = 30;

export const SHOPIFY_CUSTOMER_TOKEN_COOKIE = 'shopify_customerToken';

export const DEFAULT_VARINAT_OPTION_NAME = 'Title';

export const DEFAULT_VARINAT_OPTION_VALUE = 'Default Title';
