import { gql } from '@apollo/client';

export const PRODUCT_CONNECTION = `
  id
  title
  tags
  handle
  description
  createdAt
  vendor
  images(first: 1) {
    edges {
      node {
        transformedSrc
        altText
      }
    }
  }
  priceRange {
    minVariantPrice {
      amount
      currencyCode
    }
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  variants(first: 10) {
    edges {
      cursor
      node {
        id
        price
      }
    }
  }
  collections(first: 10) {
    edges {
      node {
        id
        title
      }
    }
  }
`;

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFields on Product {
    id
    title
    tags
    handle
    description
    createdAt
    vendor
    images(first: 1) {
      edges {
        node {
          transformedSrc
          altText
        }
      }
    }
    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    variants(first: 10) {
      edges {
        cursor
        node {
          id
          price
        }
      }
    }
    collections(first: 10) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;
