/* eslint-disable no-await-in-loop */
import {
  GetBlogByHandleQueryVariables,
  initializeApollo,
  GET_ALL_ARTICLES_CURSORS_BY_BLOG_HANDLE_QUERY,
} from '../graphql';

async function getAllArticlesCursors(
  variables: GetBlogByHandleQueryVariables,
): Promise<string[]> {
  const client = initializeApollo();

  const cursors: string[] = [];
  let pageInfo;

  do {
    const { data } = await client.query({
      query: GET_ALL_ARTICLES_CURSORS_BY_BLOG_HANDLE_QUERY,
      variables: {
        ...variables,
        ...(cursors.length > 0 && {
          after: cursors[cursors.length - 1],
        }),
      },
      fetchPolicy: 'no-cache',
    });
    const { articles } = data?.blogByHandle ?? {};
    const { edges } = articles ?? {};

    pageInfo = articles?.pageInfo;
    edges?.forEach((edge) => {
      cursors.push(edge.cursor);
    });
  } while (pageInfo?.hasNextPage);

  return cursors;
}

export default getAllArticlesCursors;
