import { styled, keyframes, alpha } from '@mui/system';
import { color } from 'theme.material';

const rotate = keyframes`
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
`;
export const SVG = styled('svg')`
  animation: ${rotate} 1s infinite ease;
`;

function LoadingIndicator({
  customColor = color.teal[500],
}: {
  customColor?: string;
}): JSX.Element {
  return (
    <SVG
      fill="none"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        strokeWidth="4"
        stroke={alpha(customColor, 0.25)}
      />
      <path
        fill={alpha(customColor, 0.75)}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </SVG>
  );
}

export default LoadingIndicator;
