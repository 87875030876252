/* eslint-disable @typescript-eslint/naming-convention */
import getPublicConfig from '@/utils/getPublicConfig';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
} from '@apollo/client';

const publicRuntimeConfig = getPublicConfig();
const {
  STOREFRONT_API_GRAPHQL,
  X_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
} = publicRuntimeConfig;

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient() {
  const httpLink = new HttpLink({
    uri: STOREFRONT_API_GRAPHQL,
    headers: {
      'X-Shopify-Storefront-Access-Token': X_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
      Accept: 'application/graphql',
    },
    fetch,
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: httpLink,
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(
  initialState = null,
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient();

  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  if (typeof window === 'undefined') return _apolloClient;

  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return apolloClient;
}
