import { gql } from '@apollo/client';
import { BLOG_FRAGMENT, ARTICLE_FRAGMENT } from '../fragments';

export const GET_BLOGS_QUERY = gql`
  query GetAllBlogs(
    $afterBlogs: String
    $beforeBlogs: String
    $firstBlogs: Int = 250
    $lastBlogs: Int
    $queryBlogs: String
    $reverseBlogs: Boolean
    $sortKeyBlogs: BlogSortKeys
    $afterArticles: String
    $beforeArticles: String
    $firstArticles: Int = 250
    $lastArticles: Int
    $queryArticles: String
    $reverseArticles: Boolean = true
    $sortKeyArticles: ArticleSortKeys = UPDATED_AT
  ) {
    blogs(
      after: $afterBlogs
      before: $beforeBlogs
      first: $firstBlogs
      last: $lastBlogs
      query: $queryBlogs
      reverse: $reverseBlogs
      sortKey: $sortKeyBlogs
    ) {
      edges {
        node {
          ...BlogFields
          articles(
            after: $afterArticles
            before: $beforeArticles
            first: $firstArticles
            last: $lastArticles
            query: $queryArticles
            reverse: $reverseArticles
            sortKey: $sortKeyArticles
          ) {
            edges {
              node {
                ...ArticleFields
              }
            }
          }
        }
      }
    }
  }
  ${BLOG_FRAGMENT}
  ${ARTICLE_FRAGMENT}
`;

export const GET_BLOG_BY_HANDLE_QUERY = gql`
  query GetBlogByHandle(
    $handle: String!
    $after: String
    $before: String
    $first: Int = 250
    $last: Int
    $query: String
    $reverse: Boolean = true
    $sortKey: ArticleSortKeys = UPDATED_AT
  ) {
    blogByHandle(handle: $handle) {
      ...BlogFields
      articles(
        after: $after
        before: $before
        first: $first
        last: $last
        query: $query
        reverse: $reverse
        sortKey: $sortKey
      ) {
        edges {
          node {
            ...ArticleFields
          }
        }
      }
    }
  }
  ${BLOG_FRAGMENT}
  ${ARTICLE_FRAGMENT}
`;

export const GET_ARTICLE_BY_HANDLE_QUERY = gql`
  query GetArticleByHandle($blogHandle: String!, $articleHandle: String!) {
    blogByHandle(handle: $blogHandle) {
      articleByHandle(handle: $articleHandle) {
        ...ArticleFields
      }
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const GET_ARTICLES_QUERY = gql`
  query GetArticles(
    $after: String
    $before: String
    $first: Int = 250
    $last: Int
    $query: String
    $reverse: Boolean = true
    $sortKey: ArticleSortKeys = UPDATED_AT
  ) {
    articles(
      after: $after
      before: $before
      first: $first
      last: $last
      query: $query
      reverse: $reverse
      sortKey: $sortKey
    ) {
      edges {
        cursor
        node {
          ...ArticleFields
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const GET_ALL_ARTICLES_CURSORS_BY_BLOG_HANDLE_QUERY = gql`
  query GetAllArticlesCursorsByBlogHandle(
    $handle: String!
    $after: String
    $before: String
    $first: Int = 250
    $last: Int
    $query: String
    $reverse: Boolean = true
    $sortKey: ArticleSortKeys = UPDATED_AT
  ) {
    blogByHandle(handle: $handle) {
      articles(
        after: $after
        before: $before
        first: $first
        last: $last
        query: $query
        reverse: $reverse
        sortKey: $sortKey
      ) {
        edges {
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
