import { gql } from '@apollo/client';
import { CHECKOUT_DETAILS_FRAGMENT } from '../fragments';

export const CHECKOUT_CREATE_MUTATION = gql`
  mutation CheckoutCreate {
    checkoutCreate(input: {}) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutDetailsFields
      }
    }
  }
  ${CHECKOUT_DETAILS_FRAGMENT}
`;

export const CHECKOUT_LINE_ITEM_REPLACE_MUTATION = gql`
  mutation CheckoutLineItemsReplace(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) {
    checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutDetailsFields
      }
    }
  }
  ${CHECKOUT_DETAILS_FRAGMENT}
`;

export const CHECKOUT_SHIPPING_ADDRESS_UPDATE_MUTATION = gql`
  mutation CheckoutShippingAddressUpdate(
    $checkoutId: ID!
    $shippingAddress: MailingAddressInput!
  ) {
    checkoutShippingAddressUpdateV2(
      checkoutId: $checkoutId
      shippingAddress: $shippingAddress
    ) {
      checkoutUserErrors {
        code
        field
        message
      }
      checkout {
        ...CheckoutDetailsFields
      }
    }
  }
`;
