import { gql } from '@apollo/client';
import { PAGE_BY_HANDLE_FRAGMENT } from '../fragments/page.fragments';

export const GET_PAGE_BY_HANDLE = gql`
  query GetPageByHandle($handle: String!) {
    pageByHandle(handle: $handle) {
      ...PageFields
    }
  }
  ${PAGE_BY_HANDLE_FRAGMENT}
`;
