// @TODO: refactor

function getIdForAdminApi(idStoreFront) {
  const str = atob(idStoreFront);
  const id = str.substring(str.lastIndexOf('/') + 1);
  const numberTypeId = Number(id);
  return Number.isNaN(numberTypeId) ? id : numberTypeId;
}

export const normalizeProducts = (products) => {
  const arrPr = products?.edges?.map((p) => p.node) ?? [];
  const product = arrPr?.map((i) => {
    const id = getIdForAdminApi(i.id);
    const variantId = getIdForAdminApi(i.variants.edges[0].node.id);

    return {
      title: i.title,
      id,
      image: { src: i.images.edges[0].node.transformedSrc },
      variants: [{ price: i.variants.edges[0].node.price, id: variantId }],
      collections: { ...i.collections.edges[0]?.node },
      vendor: i.vendor,
    };
  });
  return product;
};
