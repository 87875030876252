import { gql } from '@apollo/client';

export const CHECKOUT_DETAILS_FRAGMENT = gql`
  fragment CheckoutDetailsFields on Checkout {
    id
    webUrl
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    completedAt
    createdAt
    taxesIncluded
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          customAttributes {
            key
            value
          }
          title
          variant {
            id
            sku
            title
            product {
              id
              vendor
            }
            selectedOptions {
              name
              value
            }
            image {
              originalSrc
              altText
              width
              height
            }
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
          }
          quantity
        }
      }
    }
    shippingAddress {
      address1
      address2
      city
      company
      country
      firstName
      id
      lastName
      latitude
      longitude
      name
      phone
      province
      zip
    }
  }
`;
