import { gql } from '@apollo/client';
import { CHECKOUT_DETAILS_FRAGMENT } from '../fragments';

export const GET_CHECKOUT_QUERY = gql`
  query GetCheckout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ...CheckoutDetailsFields
    }
  }
  ${CHECKOUT_DETAILS_FRAGMENT}
`;
